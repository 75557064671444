export const logos = [
    '20100.png',
    '3m.png',
    'acea.png',
    'adc.png',
    'adecco.png',
    'adverteam.png',
    'aim-group.png',
    'all-comunication.png',
    'angelini.png',
    'ania.png',
    'arena.png',
    'atlantia.png',
    'azimut.png',
    'barilla.png',
    'bea-italia.png',
    'bea-world.png',
    'benetti.png',
    'blu-vacanze.png',
    'bper.png',
    'brother_sisters.png',
    'canon.png',
    'casale.png',
    'cdp-tricolore.png',
    'ceip.png',
    'confapi.png',
    'confindustria.png',
    'csp.png',
    'dacia.png',
    'davines.png',
    'disney.png',
    'dremel.png',
    'edenred.png',
    'edison.png',
    'escp.png',
    'facebook.png',
    'farmindustria.png',
    'ferrari.png',
    'festival-buone-notizie.png',
    'frame.png',
    'hbc.png',
    'hines.png',
    'i-art-fivas.png',
    'ied.png',
    'inda.png',
    'intesa.png',
    'intimissimi.png',
    'invesco.png',
    'invisalign.png',
    'italia-online.png',
    'just-people.png',
    'kia.png',
    'lenovo.png',
    'leo-pharma.png',
    'lifegate.png',
    'lilly.png',
    'magliano.png',
    'maki.png',
    'moncler.png',
    'mondadori.png',
    'mundys.png',
    'nestle.png',
    'next.png',
    'ninetynine.png',
    'noloop.png',
    'nos.png',
    'odisseo.png',
    'peroni.png',
    'pianob.png',
    'piemonte2027.png',
    'prodea.png',
    'pulsee.png',
    'q8.png',
    'qualefuturo.png',
    'reale-foundation.png',
    'regione-piemonte.png',
    'regione-toscana.png',
    'rizzoli.png',
    'samsung.png',
    'san-pellegrino.png',
    'sanofi.png',
    'sara.png',
    'sephora.png',
    'serviceplan.png',
    'sloggi.png',
    'snam.png',
    'sopra-steria.png',
    'sts.png',
    't-communication.png',
    'takeda.png',
    'telmotor.png',
    'terna.png',
    'the-goodness.png',
    'triumph.png',
    'unito.png',
    'univers.png',
    'universal-music.png',
    'visionary-days.png',
    'vorwerk-bimby.png',
    'we-are-here.png',
    'will.png',
    'wired.png',
    'zurich.png'
];
