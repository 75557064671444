import {Box, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {useTheme} from "@mui/material/styles";

const HomeVideo = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation()

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <Typography
                variant={isMobile ? 'h4' : 'h1'}
                textAlign={'center'}
                width={isMobile ? '95%' : '90%'}
                sx={{
                    position: 'absolute',
                    top: isMobile ? '15vh' : '12vh',
                    zIndex:'500',
                    textShadow:'1px 1px 5px #000'
                }}
                fontFamily={'Proto Grotesk'}
                fontWeight={'bold'}
            >
                {t('homeVideo.video')} <br/>
            </Typography>
            <Typography
                variant={isMobile ? 'h5' : 'h3'}
                textAlign={'center'}
                width={isMobile ? '95%' : '90%'}
                sx={{
                    position: 'absolute',
                    top: isMobile ? '20vh' : '24vh',
                    zIndex:'500',
                    textShadow:'1px 1px 5px #000'
                }}
                fontFamily={'Proto Grotesk'}
                fontWeight={'bold'}
            >
                {t('homeVideo.video2')}
            </Typography>
            <Box sx={{
                position:'relative',
                width:'100vw',
                height:'100vh',
                overflow:'hidden'
            }}>
                <video src="/videos/video-main.webm"
                       title="YouTube video player"
                       style={{
                           position:'absolute',
                           top:'50%',
                           left:'50%',
                           transform: 'translate(-50%,-50%)',
                           width: '100%',
                           height: '100%',
                           objectFit: 'cover'
                       }}
                       autoPlay loop muted playsInline
                       disablePictureInPicture>
                </video>
            </Box>
            {/*<iframe
                src="https://vimeo.com/166094859?background=1&autoplay=1&loop=1&muted=1"
                style={{
                    width: '100vw',
                    height: '100vh',
                    border: 'none',
                }}
                allow="autoplay; fullscreen"
            />*/}
            {/*<iframe src="https://www.youtube.com/embed/ScMzIvxBSi4?si=UnAgFqIcG7zdoQ2w"
                    title="YouTube video player"
                    style={{
                        width: '100vw',
                        height: '100vh',
                        border: 'none',
                    }}
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;"
                    referrerPolicy="strict-origin-when-cross-origin"></iframe>*/}
        </Box>
    )
}

export default HomeVideo;