import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {features} from "../data/features";


const FeatureMenu = ({onSelect}) => {
    const isMobile = useMediaQuery('(max-width:600px)');

    const [selected, setSelected] = useState(features[0]);

    useEffect(() => {
        onSelect(selected);

    }, []);

    const handleFeatureClick = (feature) => {
        onSelect(feature); // Trasmette il valore selezionato al componente padre
        setSelected(feature);
    };

    return (
        <Box
            sx={{
                borderRadius: "30px",
                padding: "16px",
                background:
                    'linear-gradient(360deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                width: "100%",
                margin: isMobile ? 0 : "auto",
                overflowX: 'hidden', // Assicura che il Box principale non esca dallo schermo
            }}
        >
            {isMobile ?
                <Box
                    sx={{
                        width:'95%',
                        display: 'flex',
                        overflowX: 'auto', // Abilita lo scroll orizzontale
                        paddingBottom: '8px', // Evita che la scrollbar sovrapponga il contenuto
                    }}
                >
                    {features.map((feature, index) => (
                        <Box
                            key={index}
                            onClick={() => handleFeatureClick(feature)}
                            sx={{
                                minWidth: 'calc(50% / 3.5)', // Mostra 3 elementi e mezzo
                                marginRight: '16px', // Spaziatura tra gli elementi
                                backgroundColor: feature === selected ? "#212155" : "#fff",
                                borderRadius: "20px",
                                padding: "8px 16px",
                                textAlign: "center",
                                cursor: "pointer",
                                transition: "background-color 0.5s ease",
                                "&:hover": {
                                    backgroundColor: feature === selected ? "#212155" : "#b9cefc",
                                },
                                flexShrink: 0, // Impedisce il ridimensionamento degli elementi
                            }}
                        >
                            <Typography
                                variant="body1"
                                color={feature === selected ? '#fff' : '#000'}
                            >
                                {feature.title}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                :
                <Grid container spacing={2}>
                    {features.map((feature, index) => (
                        <Grid item xs={12 / 5} key={index}>
                            <Box
                                onClick={() => handleFeatureClick(feature)}
                                sx={{
                                    backgroundColor:feature === selected ? "#212155" : "#fff",
                                    borderRadius: "20px",
                                    padding: "8px 16px",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    transition: "background-color 0.5s ease",
                                    "&:hover": {
                                        backgroundColor: feature === selected ? "#212155" : "#b9cefc",
                                    },
                                }}
                            >
                                <Typography variant="body1" color={feature === selected ? '#fff': '#000'}>
                                    {feature.title}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            }

        </Box>
        )
}

export default FeatureMenu;