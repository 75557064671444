export const videoCards = [
    {
        id: 1,
        title: "Brainstorming Collettivo ",
        translationKey: "brainstorming",
        description: "Consente ai partecipanti di esprimere le proprie opinioni attraverso parole e frasi che vengono raccolte e analizzate in tempo reale.",
        asset: "brainstorming.webm",
    },
    {
        id: 2,
        title: "Selfie AI",
        translationKey: "selfie",
        description: "Trasforma i selfie inviati in immagini artistiche o composizioni personalizzate, creando un contenuto condivisibile in tempo reale.",
        asset: "selfie-AI.webm"
    },
    {
        id: 3,
        title: "Musicstorm",
        translationKey: "musicstorm",
        description: "Raccoglie gli input dei partecipanti – sondaggi, opinioni ed emozioni – per generare una canzone originale.",
        asset: "musicstorm-AI.webm",
    },
    {
        id: 4,
        title: "Wordstorm",
        translationKey: "wordstorm",
        description: "Permette ai partecipanti di inviare parole su un tema, creando in tempo reale una rappresentazione visiva dinamica.",
        asset: "wordstorm-AI.webm"
    },
    {
        id: 5,
        title: "AI Summary",
        translationKey: "summary",
        description: "Sintetizza in tempo reale i contenuti degli interventi, generando riassunti chiari e immediati per i partecipanti.",
        asset: "summary-AI.webm",
    },
    /*{
        id: 6,
        title: "Q&A con AI",
        translationKey: "qea",
        description: "Raccoglie, modera e organizza in tempo reale le domande dei partecipanti, offrendo interazioni fluide e ottimizzate.",
        asset: "Q&A.webm",
    },*/
];
