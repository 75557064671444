import React, {useEffect} from "react";
import {Outlet, useParams} from "react-router-dom";
import {useNavigate} from "react-router-dom";
import i18n from "i18next";
import {Helmet} from "react-helmet-async";

export const LanguageRoute = ({children}) => {
    const {lang} = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if (lang && i18n.languages.includes(lang)) {
            i18n.changeLanguage(lang);
        } else {
            navigate('/en', { replace: true });
        }
    }, [lang, navigate]);

    return (
        <div>
            <Helmet>
                <title>2ndStage - Activate your Audience</title>
                <meta lang="en" name="title" content="2ndStage - Activate your Audience"/>
                <meta lang="en" name="description"
                      content="2ndStage is the interactive technology that allows the viewer to be the protagonist of the show, using their own smartphone. Engage and monetize your audience with a unique and extraordinary experience."/>
                <meta lang="en" itemProp="name" content="2ndStage - Activate your Audience"/>
                <meta lang="en" itemProp="description"
                      content="2ndStage is the interactive technology that allows the viewer to be the protagonist of the show, using their own smartphone. Engage and monetize your audience with a unique and extraordinary experience."/>
                <meta lang="en" itemProp="image" content="https://2ndstage.app/logo-2ndstage.png"/>
                <meta lang="en" property="og:url" content="https://2ndstage.app"/>
                <meta lang="en" property="og:type" content="website"/>
                <meta lang="en" property="og:title"
                      content="2ndStage - Activate your Audience"/>
                <meta lang="en" property="og:description"
                      content="2ndStage is the interactive technology that allows the viewer to be the protagonist of the show, using their own smartphone. Engage and monetize your audience with a unique and extraordinary experience."/>
                <meta lang="en" property="og:image"
                      content="https://2ndstage.app/logo-2ndstage.png"/>

                <meta lang="en" name="twitter:card"
                      content="summary_large_image"/>
                <meta lang="en" name="twitter:title"
                      content="2ndStage - Activate your Audience"/>
                <meta lang="en" name="twitter:description"
                      content="2ndStage is the interactive technology that allows the viewer to be the protagonist of the show, using their own smartphone. Engage and monetize your audience with a unique and extraordinary experience."/>
                <meta lang="en" name="twitter:image"
                      content="https://2ndstage.app/logo-2ndstage.png"/>
            </Helmet>
            {
                children ?
                    children
                    :
                    <Outlet/>
            }
        </div>
    );
}
