import {CircularProgress} from "@mui/material";
import React from "react";

const Loader = () => {
    return (
        <div style={loaderStyles}>
            <img src={'/logo-2ndstage.svg'} alt={'logo'}/>
            <CircularProgress sx={{color: '#ea528a',marginTop:'3rem'}}/>
            {/* Puoi aggiungere un'animazione o un logo qui */}
        </div>
    )
}

const loaderStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width:'100vw',
    backgroundColor: '#131331',
};

export default Loader;