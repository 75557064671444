import {Typography} from "@mui/material";
import {useRef} from "react";

import videoCardStyle from '../stylesheets/VideoCard.module.css';
import {useTranslation} from "react-i18next";

const VideoCard = ({card}) => {
    const videoRef = useRef(null);

    const {t} = useTranslation();

    const handleMouseEnter = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const handleMouseLeave = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };

    return (
        <div className={videoCardStyle.videoCard} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                <video
                    ref={videoRef}
                    className={videoCardStyle.backgroundVideo}
                    src={`/videos/cards/${card.asset}`}
                        loop playsInline
                />
                <div className={videoCardStyle.titleContainer}>
                </div>
                <Typography className={videoCardStyle.title} fontFamily={'Poppins'} variant={'h6'} fontWeight={'500'} >
                    {t(`aicard.${card.translationKey}.title`)}
                </Typography>
                <p className={videoCardStyle.description}>
                    {t(`aicard.${card.translationKey}.content`)}
                </p>

        </div>
    );
};

export default VideoCard;