import {Box, Typography, useMediaQuery} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import barraClientiStyle from '../stylesheets/BarraClienti.module.css';
import {logos} from "../data/logos";



const BarraClienti = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation()

    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    const shuffledLogos = shuffleArray(logos);

    return (
        <div className={barraClientiStyle.scrollingContainer}>
            <div className={barraClientiStyle.scrollingContent}>
                {shuffledLogos && shuffledLogos.concat(shuffledLogos).map((logo, index) => (
                    <div className={barraClientiStyle.logoContainer}>
                        <img src={`/images/portfolio/clients-logos/${logo}`} alt={`Collaborator ${index + 1}`} key={index} className={barraClientiStyle.logo}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default BarraClienti;