import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import {styled} from "@mui/material";

const StyledSwiper = styled(Swiper)`
  .swiper-button-next,
  .swiper-button-prev {
    color: #fff; /* Cambia il colore delle frecce */
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    color: #fff; /* Cambia colore quando l'utente passa sopra */
  }
`;



const ImageCarousel = ({ images }) => {

    return (
            <StyledSwiper
                modules={[Navigation]}
                navigation
                loop
                style={{ width: '100%', height: '100%' }}
            >
                {images.map((image, index) => (
                    <SwiperSlide key={index}>
                        <img
                            src={`/images/portfolio/progetti/${image}`}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                borderRadius: '25px',
                            }}
                            alt={`Slide ${index}`}
                        />
                    </SwiperSlide>
                ))}
            </StyledSwiper>
    );
};

export default ImageCarousel;
