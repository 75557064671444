import {Box, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import DataCard from "./DataCard";
import {NavLink, useLocation, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";


const DataSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation();

    const { lang } = useParams();
    const location = useLocation(); // Ottieni il path corrente
    const getPath = (path) => (lang ? `/${lang}${path}` : path);

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundImage:isMobile ? 'url("/images/sezione-dati-bkg-mobile.png")' : 'url("/images/sezione-dati-bkg.png")',
            backgroundPosition: isMobile ? 'left' : 'top',
            backgroundSize:'cover',
            overflow: 'hidden',
            width: '100%',
        }}>
            <Box sx={{display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop:'15vh'
            }}>
                <Typography variant={'h5'} fontWeight={'normal'}>Engagement Rate</Typography>
                <Typography variant={'h1'} fontFamily={'Proto Grotesk'} fontWeight={'700'} fontSize={isMobile ? '35vw !important' : '50vh !important'}>80%</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginTop:isMobile ? '5vh' : '8vh'
            }}>
                {isMobile ?
                    <>
                        <Grid container spacing={2} direction="column" alignItems="center">
                            {/* RIGA 1 */}
                            <Grid container spacing={2} item justifyContent="center">
                                <Grid item md={4} xs={6}>
                                    <DataCard emoji={'🙋‍♂️'} title={'utenti'} numero={200000} organicSpeedFactor={1}/>
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <DataCard emoji={'🎬️'} title={'eventi'} numero={300} organicSpeedFactor={20}/>
                                </Grid>
                            </Grid>

                            {/* RIGA 2 */}
                            <Grid container spacing={2} item justifyContent="center">
                                <Grid item md={4} xs={6}>
                                    <DataCard emoji={'🙆‍♀️'} title={'reaction'} numero={4000000} organicSpeedFactor={0.2}/>
                                </Grid>
                                <Grid item md={4} xs={6} >
                                    <DataCard emoji={'👍'} title={'sondaggi'} numero={1000000} organicSpeedFactor={1.5}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>
                    :
                    <>
                        <Grid container spacing={10} direction="column" alignItems="center">
                            {/* RIGA 1 */}
                            <Grid container spacing={10} item justifyContent="center">
                                <Grid item md={4} xs={6}>
                                    <DataCard emoji={'🙋‍♂️'} title={'utenti'} numero={200000} organicSpeedFactor={1}/>
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <DataCard emoji={'🎬️'} title={'eventi'} numero={300} organicSpeedFactor={20}/>
                                </Grid>
                                <Grid item md={4} xs={6}>
                                    <DataCard emoji={'🎯'} title={'domande'} numero={30000} organicSpeedFactor={2}/>
                                </Grid>
                            </Grid>

                            {/* RIGA 2 */}
                            <Grid container spacing={10} item justifyContent="center">
                                <Grid item md={4} xs={6}>
                                    <DataCard emoji={'🙆‍♀️'} title={'reaction'} numero={4000000} organicSpeedFactor={0.1}/>
                                </Grid>
                                <Grid item md={4} xs={6} >
                                    <DataCard emoji={'👍'} title={'sondaggi'} numero={1000000} organicSpeedFactor={1.5}/>
                                </Grid>
                                <Grid item md={4} xs={4}>
                                    <DataCard emoji={'🗣️'} title={'parole'} numero={150000} organicSpeedFactor={0.7}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </>

                }

            </Box>
            <Button component={NavLink} to={getPath('/contattaci')} variant={'contained'} sx={{marginTop:'10vh', marginBottom:'20vh'}}>
                <Typography pl={'10px'} pr={'10px'} variant={'h6'} fontFamily={'Poppins'} fontWeight={'400'}>{t(`dataSection.action`)}</Typography>
            </Button>
        </Box>
    )
}

export default DataSection;