

export const portfolioCards = [
    {
        id: 1,
        translationKey: "ferrari",
        title: "FERRARI CAR LAUNCH",
        background: "ferrari/ferrari-bkg.png",
        thumbnailBackground: "ferrari/ferrari-thumbnail-bkg.png",
        backgroundVideo:"https://player.vimeo.com/video/1033863470?h=8897f86a83&autoplay=1&loop=1&muted=1&background=1",
        modalVideo:"https://player.vimeo.com/video/1033863470?h=8897f86a83",
        clients: ['ferrari.png', 'ninetynine.png' ],
        inEvidenza:true,
    },
    {
        id: 2,
        translationKey: "lifegate",
        title: "LIFEGATE",
        background: "lifegate/lifegate-bkg.jpg",
        thumbnailBackground: "lifegate/lifegate-bkg.jpg",
        images: ["lifegate/lifegate-bkg.jpg", "lifegate/lifegate-img1.jpg", "lifegate/lifegate-img2.jpg"],
        clients: ['lifegate.png'],
        inEvidenza:false,
    },
    /*{
        id: 3,
        translationKey: "italiaonline",
        title: "ITALIA ONLINE - strategy day",
        background: "italiaonline/italiaonline-bkg.jpg",
        thumbnailBackground: "italiaonline/italiaonline-bkg.jpg",
        images: ["italiaonline/italiaonline-bkg.jpg", "italiaonline/italiaonline-img1.jpg", "italiaonline/italiaonline-img2.jpg"],
        clients: ['italia-online.png', 'sts.png'],
        inEvidenza:true,
    },*/
    {
        id: 4,
        translationKey: "costanavarino",
        title: "BOLTON FOOD",
        background: "costanavarino/costanavarino-bkg.jpg",
        thumbnailBackground: "costanavarino/costanavarino-bkg.jpg",
        images: ["costanavarino/costanavarino-bkg.jpg", "costanavarino/costanavarino-img1.jpg", "costanavarino/costanavarino-img2.jpg","costanavarino/costanavarino-img3.jpg"],
        clients: ['triumph.png'],
        inEvidenza:false,
    },
    {
        id: 5,
        translationKey: "statigenerali",
        title: "STATI GENERALI DEGLI EVENTI",
        background: "statigenerali/statigenerali-bkg.jpg",
        thumbnailBackground: "statigenerali/statigenerali-bkg.jpg",
        images: ["statigenerali/statigenerali-bkg.jpg", "statigenerali/statigenerali-img1.png", "statigenerali/statigenerali-img2.png","statigenerali/statigenerali-img3.png","statigenerali/statigenerali-img4.jpg"],
        clients: ['adc.png'],
        inEvidenza:false,
    },
    {
        id: 6,
        translationKey: "realemutua",
        title: "WE PLAY 4 REAL",
        background: "realemutua/realemutua-bkg.jpg",
        thumbnailBackground: "realemutua/realemutua-bkg.jpg",
        images: ["realemutua/realemutua-bkg.jpg", "realemutua/realemutua-img1.jpg", "realemutua/realemutua-img2.jpg","realemutua/realemutua-img3.jpg"],
        clients: ['pianob.png'],
        inEvidenza:false,
    },
    {
        id: 7,
        translationKey: "q8",
        title: "CONVENTION Q8",
        background: "q8/q8-bkg.png",
        thumbnailBackground: "q8/q8-bkg.png",
        images: ["q8/q8-bkg.png", "q8/q8-img1.png", "q8/q8-img2.png"],
        clients: ['next.png', 'q8.png'],
        inEvidenza:false,
    },


    {
        id: 8,
        translationKey: "sanpellegrino",
        title: "SANPELLEGRINO",
        background: "sanpellegrino/sanpellegrino-bkg.jpg",
        thumbnailBackground: "sanpellegrino/sanpellegrino-bkg.jpg",
        images: ["sanpellegrino/sanpellegrino-bkg.jpg", "sanpellegrino/sanpellegrino-img1.jpg", "sanpellegrino/sanpellegrino-img2.jpg"],
        clients: ['20100.png','san-pellegrino.png'],
        inEvidenza:false,
    },


    {
        id: 9,
        translationKey: "invisalign",
        title: "INVISALIGN-INNOVATION DAY",
        background: "invisalign/invisalign-bkg.jpg",
        thumbnailBackground: "invisalign/invisalign-bkg.jpg",
        images: ["invisalign/invisalign-bkg.jpg", "invisalign/invisalign-img1.jpg", "invisalign/invisalign-img2.jpg"],
        clients: ['triumph.png', 'invisalign.png'],
        inEvidenza:false,
    },


    {
        id: 10,
        translationKey: "fbn",
        title: "FESTIVAL DELLE BUONE NOTIZIE",
        background: "fbn/fbn-bkg.jpg",
        thumbnailBackground: "fbn/fbn-bkg.jpg",
        images: ["fbn/fbn-bkg.jpg", "fbn/fbn-img1.jpg", "fbn/fbn-img2.jpg", "fbn/fbn-img3.jpg"],
        clients: ['festival-buone-notizie.png'],
        inEvidenza:true,
    },


    {
        id: 11,
        translationKey: "beaworld",
        title: "BEA WORLD",
        background: "beaworld/beaworld-bkg.jpg",
        thumbnailBackground: "beaworld/beaworld-bkg.jpg",
        images: ["beaworld/beaworld-bkg.jpg", "beaworld/beaworld-img1.jpg", "beaworld/beaworld-img2.jpg"],
        clients: ['adc.png'],
        inEvidenza:false,
    },
    {
        id: 12,
        translationKey: "stereoteepee",
        title: "STEREOTEEPEE FESTIVAL",
        background: "stereoteepee/stereoteepee-bkg.png",
        thumbnailBackground: "stereoteepee/stereoteepee-bkg.png",
        images: ["stereoteepee/stereoteepee-bkg.png", "stereoteepee/stereoteepee-img1.png", "stereoteepee/stereoteepee-img2.png"],
        clients: ['maki.png'],
        inEvidenza:false,
    },
    /*{
        id: 13,
        translationKey: "nextgeneration",
        title: "NEXT GENERATION FEST",
        background: "https://vimeo.com/1033861547/c5961f816d",
        thumbnailBackground: "nextgen/nextgen-bkg.png",
        backgroundVideo:"https://player.vimeo.com/video/1033861547?h=c5961f816d&autoplay=1&loop=1&muted=1&background=1",
        clients: ['regionetoscana.png'],
        inEvidenza:true,
    },*/


    {
        id: 14,
        translationKey: "tiktok",
        title: "TIK TOK AWARDS",
        background: "https://vimeo.com/1033864090/8f43d29605",
        thumbnailBackground: "tiktok/tiktok-bkg.png",
        backgroundVideo:"https://player.vimeo.com/video/1033864090?h=8f43d29605&autoplay=1&loop=1&muted=1&background=1",
        inEvidenza:false,
    },


    {
        id: 15,
        translationKey: "edenred",
        title: "EDENRED E-DAY",
        background: "https://vimeo.com/1033864384/7c8965f3ee",
        thumbnailBackground: "edenred/edenred-bkg.png",
        backgroundVideo:"https://player.vimeo.com/video/1033864384?h=7c8965f3ee&autoplay=1&loop=1&muted=1&background=1",
        clients: ['univers.png', 'edenred.png'],
        inEvidenza:false,
    },


    {
        id: 16,
        translationKey: "outofoffice",
        title: "OUT OF OFFICE",
        background: "https://vimeo.com/1033868550/bdee7ecbb2",
        thumbnailBackground: "outofoffice/outofoffice-bkg.png",
        backgroundVideo:"https://player.vimeo.com/video/1033868550?h=bdee7ecbb2&autoplay=1&loop=1&muted=1&background=1",
        clients: ['unito.png'],
        inEvidenza:false,
    },


    {
        id: 17,
        translationKey: "edenred2",
        title: "CONVENTION EDENRED",
        background: "https://vimeo.com/1033865531/4c8be96a8b",
        thumbnailBackground: "edenred2/edenred2-bkg.png",
        backgroundVideo:"https://player.vimeo.com/video/1033865531?h=4c8be96a8b&autoplay=1&loop=1&muted=1&background=1",
        clients: ['univers.png', 'edenred.png'],
        inEvidenza:true,
    },
    {
        id: 18,
        translationKey: "soprasteria",
        title: "SOPRA STERIA",
        background: "",
        thumbnailBackground: "soprasteria/soprasteria-bkg.png",
        backgroundVideo:"https://player.vimeo.com/video/1033866035?h=1dbdbe2715&autoplay=1&loop=1&muted=1&background=1",
        clients: ['peroni.png', 'sopra-steria.png'],
        inEvidenza:true,
    },


];