import portfolioCardStyle from "../stylesheets/PortfolioCard.module.css";
import {Typography, useMediaQuery} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";


const PortfolioCard = ({title, isSelected, onClick, card}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const { t } = useTranslation();

    return (
        <div className={`${portfolioCardStyle.mainContainer} ${isSelected && portfolioCardStyle.selected}`}
             onClick={onClick}
             style={{
                 backgroundImage: `url("/images/portfolio/progetti/${card?.thumbnailBackground}")`
             }}
        >
            <div className={portfolioCardStyle.container}>

            </div>
            <div className={portfolioCardStyle.contentContainer}>
                <Typography variant={isMobile ? 'h5' : 'h6'}>{title}</Typography>
                <Typography className={portfolioCardStyle.description} variant={'body'} fontWeight={'normal'} fontSize={'14px'}>
                    {t(`portfolio.progetti.${card.translationKey}.thumbDescription`)}
                </Typography>
            </div>
        </div>
    )
}

export default PortfolioCard;