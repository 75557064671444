import {useTranslation} from "react-i18next";

const Features = () => {
    const {t} = useTranslation()

    return(
        <div>
            <h1 style={{color:'#fff'}}>{t('test.features')}</h1>
        </div>
    )
}

export default Features
