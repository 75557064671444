import {Alert, Box, Button, CircularProgress, Snackbar, TextField, Typography, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import Footer from "../components/Footer";


const Contact = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation();

    const apiKey = process.env.REACT_APP_BREVO_API_KEY // Inserisci la chiave API Brevo

    const [formData, setFormData] = useState({
        nome: "",
        email: "",
        info: "",
    });

    const [loading, setLoading] = useState(false); // Stato per mostrare caricamento
    const [error, setError] = useState(null); // Stato per errori
    const [success, setSuccess] = useState(false); // Stato per successo
    const [alertMessage, setAlertMessage] = useState(""); // Messaggio per l'alert

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    // Controlla se tutti i campi richiesti sono compilati
    const isFormValid = formData.nome.trim() !== "" && formData.email.trim() !== "";

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        const url = "https://api.brevo.com/v3/contacts";
        const listId = 41; // Sostituisci con l'ID della tua lista

        /*const normalizePhoneNumber = (number) => {
            let cleaned = number.trim();
            // Aggiunge +39 se il numero non inizia con + o 0039
            if (!cleaned.startsWith('+') && !cleaned.startsWith('0039')) {
                cleaned = `+39${cleaned}`;
            } else if (cleaned.startsWith('0039')) {
                cleaned = `+39${cleaned.slice(4)}`; // Sostituisce 0039 con +39
            }
            return cleaned;
        };

        const normalizedPhone = normalizePhoneNumber(formData.cellulare);*/


        const payload = {
            email: formData.email,
            attributes: {
                NOME: formData.nome,
                // SMS: normalizedPhone,
                INFO: formData.info,
            },
            listIds: [listId], // Specifica la lista in cui aggiungere il contatto
            updateEnabled: true, // Aggiorna il contatto se esiste già
        };

        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "api-key": apiKey,
                },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || "Errore durante l'invio");
            }

            setSuccess(true);
            setAlertMessage("Contatto registrato con successo!");

            setFormData({
                nome: "",
                email: "",
                info: "",
            });
        } catch (error) {
            setError(error.message || "Errore imprevisto");
            setAlertMessage(`Errore: ${error.message}`);
        } finally {
            setLoading(false);
        }
    };


    return(
        <Box
            sx={{
                width: '100vw',
                height: 'fit-content',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'linear-gradient(360deg, #F95F43 0%, #F1417A 100%)',
                marginTop:'75px',
                overflow:'hidden'
            }}
        >
            <Typography variant={isMobile? 'h3' : 'h2'} mt={5}>
                {t('contattaci.title')}
            </Typography>
            <Typography variant={'h4'}>
                {t('contattaci.subtitle')}
            </Typography>
            <form
                onSubmit={handleSubmit}
                style={{
                    width: isMobile ? '90%' : '45%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '1rem',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: '5vh',
                }}
            >
                <TextField
                    fullWidth
                    placeholder={t('contattaci.nome')+"*"}
                    name="nome"
                    variant="outlined"
                    value={formData.nome}
                    onChange={handleChange}
                />
                <TextField
                    fullWidth
                    placeholder="E-mail*"
                    name="email"
                    type="email"
                    variant="outlined"
                    value={formData.email}
                    onChange={handleChange}
                />
                {/*<TextField
                    fullWidth
                    placeholder={t('contattaci.numero')+"*"}
                    name="cellulare"
                    type="tel"
                    variant="outlined"
                    value={formData.cellulare}
                    onChange={handleChange}
                />*/}
                <TextField
                    fullWidth
                    placeholder="Info"
                    name="info"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={formData.info}
                    onChange={handleChange}
                />
                <Box sx={{height:'70px'}}>
                    {loading ?

                        <CircularProgress color={'#fff'} sx={{marginTop:'1rem'}}/>
                        :
                        <Button
                            variant="empty"
                            color="primary"
                            type="submit"
                            sx={{marginTop: '1rem'}}
                            disabled={!isFormValid || loading}
                        >
                            {t('contattaci.action')}
                        </Button>
                    }
                </Box>

            </form>
            <Typography variant={'body'} sx={{marginTop:'1rem'}}>
                Oppure contattaci alla mail
            </Typography>
            <Typography variant={'body'} mb={5}>
                <a href={'mailto:plesh@plesh.co'} style={{color:'#fff'}}>hello@plesh.co</a>
            </Typography>
            <Footer />
            <Snackbar
                open={success || error !== null}
                autoHideDuration={6000}
                onClose={() => {
                    setSuccess(false);
                    setError(null);
                }}
            >
                <Alert
                    onClose={() => {
                        setSuccess(false);
                        setError(null);
                    }}
                    severity={success ? "success" : "error"}
                    sx={{width: '100%'}}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>
    )
}

export default Contact;