import { useTheme } from "@mui/material/styles";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import VideoCard from "./VideoCard";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useRef, useState, useEffect } from "react";
import {HashLink} from "react-router-hash-link";
import {useLocation, useParams} from "react-router-dom";
import {videoCards} from "../data/videoCards";


const CardSection = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();

    const scrollContainerRef = useRef(null);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [sidePadding, setSidePadding] = useState(0);
    const cardWidth = 300; // Larghezza della VideoCard
    const cardGap = 20;    // Gap tra le carte
    const totalCards = 5; // Numero totale di VideoCard
    const cardTotalWidth = cardWidth + cardGap;

    const { lang } = useParams();

    const getPath = (path) => (lang ? `/${lang}${path}` : path);

    useEffect(() => {
        if (scrollContainerRef.current) {
            //const isMobileViewport = window.innerWidth <= theme.breakpoints.values.md; // Usa il breakpoint
            const targetCardIndex = 2; // Indice della terza card (partendo da 0)
            const initialScrollLeft = isMobile
                ? targetCardIndex * cardTotalWidth // Mobile: usa larghezza card
                : targetCardIndex * cardTotalWidth // Desktop: sottrai sidePadding

            scrollContainerRef.current.scrollTo({
                left: initialScrollLeft,
                behavior: 'smooth',
            });
        }
    }, [cardTotalWidth, sidePadding, theme.breakpoints.values.md]);

    // Calcola il padding laterale in base alla larghezza della viewport
    useEffect(() => {
        const updateSidePadding = () => {
            const viewportWidth = window.innerWidth;
            const padding = (viewportWidth - cardWidth) / 2;
            setSidePadding(padding > 0 ? padding : 0);
        };

        updateSidePadding();
        window.addEventListener('resize', updateSidePadding);
        return () => {
            window.removeEventListener('resize', updateSidePadding);
        };
    }, []);

    // Funzioni per gestire lo scroll orizzontale
    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            const newScrollLeft = Math.max(scrollContainerRef.current.scrollLeft - cardTotalWidth, 0);
            scrollContainerRef.current.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
        }
    };
    const scrollRight = () => {
        if (scrollContainerRef.current) {
            const maxScrollLeft = scrollContainerRef.current.scrollWidth - scrollContainerRef.current.clientWidth;
            const newScrollLeft = Math.min(scrollContainerRef.current.scrollLeft + cardTotalWidth, maxScrollLeft);
            scrollContainerRef.current.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
        }
    };

    // Gestore per l'evento scroll
    const onScroll = () => {
        if (scrollContainerRef.current) {
            const scrollLeft = scrollContainerRef.current.scrollLeft;
            const cardTotalWidth = cardWidth + cardGap;
            const newIndex = Math.round((scrollLeft + sidePadding) / cardTotalWidth);
            setCurrentIndex(newIndex);
        }
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            backgroundColor:'#fff',
            overflowX: 'hidden',
            width: '100%',
        }}>
            {/* Titolo */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#fff',
                marginTop: '10vh',
                gap: isMobile ? '0px' : '20px',
                width: isMobile ?  '90%' : '100%',
                maxWidth: '1200px', // Limitiamo la larghezza massima
                marginLeft: 'auto',
                marginRight: 'auto',
                justifyContent: 'center', // Centriamo il contenuto
            }}>
                <img src={'/images/Star.png'} alt={'star img'} style={{ width: '30px'}} />
                <Typography
                    variant={isMobile ? 'h4' : 'h3'}
                    fontFamily={'Poppins'}
                    textAlign={'center'}
                    fontWeight={'200'}
                    sx={{
                        color: '#000',
                        position: 'relative',
                    }}>
                    {t('cardSection.title')}
                </Typography>
                <img src={'/images/Star.png'} alt={'star img'} style={{ width: '30px' }} />
            </Box>

            {/* Bottoni di scorrimento */}
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                backgroundColor: '#fff',
                marginTop: '5vh',
                gap: isMobile ? '10px' : '50px',
                width: '100%',
                maxWidth: '1200px',
                marginLeft: 'auto',
                marginRight: 'auto',
                justifyContent: 'center',
                overflow:'visible',
                overflowY: 'visible',
            }}>
                {isMobile ?
                    <>
                        {[...Array(totalCards)].map((_, index) => (
                            <div
                                key={index}
                                style={{
                                    width: '10px',
                                    height: '10px',
                                    borderRadius: '50%',
                                    backgroundColor: index === currentIndex ? '#f74e60' : '#212155',
                                    transition: 'background-color 0.3s ease',
                                }}
                            ></div>
                        ))}
                    </>
                    :
                    <>
                        <Button variant={'circular'} onClick={scrollLeft}>
                            <KeyboardArrowLeft sx={{ fontSize:'40px' }} />
                        </Button>
                        <Button variant={'circular'} onClick={scrollRight}>
                            <KeyboardArrowRight sx={{ fontSize:'40px' }}/>
                        </Button>
                    </>
                }

            </Box>

            {/* Contenitore delle VideoCard */}
            <Box
                ref={scrollContainerRef}
                sx={{
                    display: 'flex',
                    alignItems:'center',
                    overflowX: 'auto',
                    overflowY: 'visible',
                    scrollBehavior: 'smooth',
                    gap: `${cardGap}px`,
                    padding: `10px ${sidePadding}px`, // Aggiungi il padding laterale calcolato
                    width: '100%',
                    '&::-webkit-scrollbar': { display: 'none' },
                    scrollSnapType: 'x mandatory',
                    height:'550px'
                }}
                onScroll={onScroll}
            >
                {/* Array di VideoCard */}
                {videoCards.map((card, index) => (
                    <Box key={index} sx={{ flex: '0 0 auto',overflowY: 'visible' }}>
                        <VideoCard card={card} />
                    </Box>
                ))}
            </Box>
            <Button component={HashLink}
                    smooth
                    to={getPath('/#features')}
                    variant={'contained'}
                    sx={{marginBottom:'5vh'}}
            >
                <Typography pl={'10px'} pr={'10px'} variant={'h6'} fontFamily={'Poppins'} fontWeight={'400'}>{t('cardSection.action')}</Typography>
            </Button>
        </Box>
    );
};

export default CardSection;
