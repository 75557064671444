import {createTheme, responsiveFontSizes} from "@mui/material";

export const pleshDark = responsiveFontSizes(createTheme({
    typography: {
        fontFamily: ['Poppins']
    },

    components:{
        MuiAppBar: {
            styleOverrides:{
                root:{
                    background:'#131331',
                    borderRadius:'0',
                    borderColor:'#131331',
                    boxShadow:'none',
                    '&:hover': {
                        background:'#131331',
                        boxShadow:'none'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                },
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    color: "#252a33",
                    "&:before": {
                        borderBottom: "none" // Rimuove la linea standard
                    },
                    "&:after": {
                        borderBottom: "none" // Rimuove la linea al focus
                    },
                    "& input": {
                        textAlign: "center"
                    }
                },
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    background: 'white',
                    borderRadius: '1rem',
                    borderColor: '#252a33',
                    boxShadow: 'none',
                    '& fieldset': {
                        borderRadius: '1rem',
                        textAlign: "left",
                    },
                    '& .MuiInputBase-input': {
                        textAlign: 'left', // Allinea il testo all'inizio
                    },
                    '& .MuiInputBase-input::placeholder': {
                        textAlign: 'left', // Allinea il placeholder
                    },
                }
            }
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    color: '#131331', // Colore testo
                    backgroundColor: '#fff', // Colore sfondo
                    borderRadius: '10px',
                    borderBottom: 'none',
                    border:'none',
                    '& .MuiSelect-icon': {
                        color: '#131331' // Colore dell'icona
                    },
                    '& .MuiSelect-select': {
                        padding: '6px', // Riduci il padding qui
                    },
                }
            }
        },
        MuiPaper: {
            styleOverrides:{
                root: {
                    borderRadius:'1rem',
                },
                gradient:{
                    borderRadius:'2rem',
                    background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #4C4C7E',
                    transition:'0.5s ease',
                    '&:hover': {
                        background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 20%, rgba(17, 17, 44,1) 100%) 100% / 200%',
                        boxShadow:'0px 0px 13px 0px rgba(76,76,126,0.72)'
                    },
                },
                come:{
                    //background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '1px solid #fff',
                    borderRadius:'2.5rem',
                    transition:'0.5s ease',
                    '&:hover': {
                        //background: 'linear-gradient(-45deg, rgba(28, 28, 61,1) 20%, rgba(17, 17, 44,1) 100%) 100% / 200%',
                        boxShadow:'0px 0px 30px 0px rgba(76,76,126,0.72)'
                    },
                },
                swipe:{
                    borderRadius:'2rem',
                    background: 'linear-gradient(0deg, rgba(28, 28, 61,1) 0%, rgba(17, 17, 44,1) 100%) 0',
                    border: '2px solid #393462',
                    transition:'0.5s ease',
                    boxShadow:'0px 0px 20px 0px rgba(0,0,0,0.46)'
                },
                demo: {
                    borderRadius:'5rem',
                    background: 'linear-gradient(0deg, rgba(234,77,149,1) 0%, rgba(231,110,81,1) 100%)',
                    backgroundRepeat:'no-repeat',
                    boxShadow: '0 0 70px rgba(229, 23, 133, 0.26)',
                    minHeight:'670px',
                    '@media (max-width: 767px)': {
                        background: 'linear-gradient(90deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                        minHeight: '0'
                    },
                }
            }
        },
        MuiButton:{
            styleOverrides: {
                root:{
                    borderRadius:'10px',
                    fontFamily:'Forever Grotesk',
                    fontWeight:'400',
                    textTransform:'none',
                    fontSize:'18px',
                    padding:'6px',
                },
                header: {
                    borderRadius:'0px',
                    '&:hover': {
                        fontWeight:'700',
                        borderBottom:'1px solid #f1417a'
                    },
                    '&:active': {
                        fontWeight:'700',
                        borderBottom:'1px solid #f1417a'
                    }
                },
                contained: {
                    color:'#fff',
                    backgroundColor: '#f1417a',
                    background: 'linear-gradient(360deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                    borderRadius:'20px',
                },
                circular: {
                    color:'#fff',
                    backgroundColor: '#f1417a',
                    background: 'linear-gradient(360deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                    borderRadius: '50%',
                    minWidth: '30px', // Imposta la larghezza per garantire la forma tonda
                    minHeight: '30px', // Imposta l'altezza per garantire la forma tonda
                    padding: '0px', // Opzionale, per regolare il padding interno
                    "&:hover": {
                        backgroundColor: 'initial', // Rimuove l'effetto hover
                        background: 'linear-gradient(360deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                    }
                },
                outlined: {
                    fontFamily:'Poppins',
                    color:'#fff',
                    border: 'none',
                    height:'35px',
                    background:'linear-gradient(360deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                    paddingTop:'0px',
                    paddingBottom:'0px',
                    width:'120px',
                    '&:hover': {
                        color:'#fff',
                        background:'linear-gradient(360deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                        fontWeight:'500'
                    }
                },
                bottomDemo: {
                    color:'#FFC3C8',
                    background:'linear-gradient(-45deg, rgba(28,28,61,1) 0%, rgba(17,17,44,1) 100%)',

                    '&:hover': {
                        boxShadow: '0 0 15px rgba(0,0,0,0.29)',
                        color:'#FFC3C8',
                        background:'linear-gradient(-45deg, rgba(28,28,61,1) 0%, rgba(17,17,44,1) 100%)',
                    }
                },
                empty: {
                    color:'#fff',
                    background:'transparent',
                    border:'1px solid #fff',
                    borderRadius:'25px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    '&:hover': {
                        background:'rgba(255,255,255,0.16)',
                    }
                },
                portfolio: {
                    color:'#fff',
                    background:'transparent',
                    border:'1px solid #f1417a',
                    borderRadius:'25px',
                    paddingLeft:'20px',
                    paddingRight:'20px',
                    '&:hover': {
                        background:'rgba(255,255,255,0.16)',
                    }
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: '#131331', // Colore dell'icona startAdornment

                }
            }
        },

    },

    palette: {
        mode: 'dark',
        primary: {
            main: '#131331',
        },
        secondary: {
            main: '#263238',
        },
        background: {
            default:'#131331',
        },
        accent: {
            main: '#f1417a',
        },
        text: {
            primary: '#eceff1',
        },
    },
    customColors: {
        clickTo: {
            main: '#FFEB3B',
        },
        wordcloud: {
            main: 'linear-gradient(90deg, rgba(234,77,149,1) 0%, rgba(231,110,81,1) 100%)'
        },
        quiz: {
            main: '#388E3C'
        },
        siNo: {
            main: '#D32F2F'
        },
        poll: {
            main: 'linear-gradient(90deg, rgba(121,216,161,1) 0%, rgba(253,228,81,1) 100%)'
        }
    }
}));
