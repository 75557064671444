// components/Layout.js
import React, {useRef} from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from './Header'; // Assicurati che il percorso sia corretto

export const Layout = () => {

    return (
        <>
            <Header/>
            <main>
                <Outlet />
            </main>
        </>
    );
};
