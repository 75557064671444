import React from 'react';
import { Box, Container, Typography, Button, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import ImageCarousel from './ImageCarousel';
import { portfolioCards } from '../data/portfolio';
import PortfolioCard from './PortfolioCard';

const ProjectModal = ({ open, onClose, card, onCardSelect }) => {
    const { t } = useTranslation();
    const { lang } = useParams();
    const location = useLocation();
    const getPath = (path) => (lang ? `/${lang}${path}` : path);

    const isMobile = useMediaQuery('(max-width:600px)');

    if (!open) return null;

    return (
        <Box
            onClick={onClose}
            sx={{
                position: 'fixed', // oppure absolute, se preferisci
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                zIndex: 1300,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-start', // inizio in alto
                overflow: 'hidden' // niente scroll sul "layer" semitrasparente
            }}
        >
            {/* Finestra vera e propria della modale */}
            <Container
                onClick={(e) => e.stopPropagation()}
                maxWidth="md"
                sx={{
                    position: 'relative',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    borderRadius: '8px',
                    marginBottom: '40px',
                    // Limitiamo l'altezza così scorre all'interno:
                    height: '100vh',
                    overflowY: 'auto',
                    p: 2,
                    pt:0
                }}
            >
                {/* Sezione STICKY (X + Video) */}
                <Box sx={{ position: 'sticky', top: 0, zIndex: 10, backgroundColor:'black', pt:2 }}>
                    {/* Pulsante X in alto a destra */}


                    {/* Video / Carousel */}
                    <Box
                        sx={{
                            width: '100%',
                            aspectRatio: '16/9',
                            borderRadius: '8px',
                            position: 'relative',
                            overflow: 'hidden'
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 8,
                                right: 8,
                                zIndex: 2,             // per stare sopra al video
                                cursor: 'pointer'
                            }}
                        >
                            <img
                                src="/images/modal-close-icon.png"
                                alt="close icon"
                                onClick={onClose}
                                style={{ width: '30px', cursor: 'pointer' }}
                            />
                        </Box>

                        {card?.backgroundVideo ? (
                            <iframe
                                src={`${card.backgroundVideo}`}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                    borderRadius: '8px'
                                }}
                                frameBorder="0"
                                allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
                                allowFullScreen
                            ></iframe>
                        ) : (
                            <ImageCarousel images={card?.images} />
                        )}
                    </Box>
                </Box>

                {/* Contenuto SCORREVOLE (testi, clienti, card simili, ecc.) */}
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h4" gutterBottom textAlign="left" color="white">
                        {card.title}
                    </Typography>
                    <Box sx={{ width: isMobile ? '100%' : '70%', color: 'white' }}>
                        <Typography variant="body1" textAlign="left">
                            <Trans
                                i18nKey={`portfolio.progetti.${card.translationKey}.innerDescription`}
                                components={{ br: <br /> }}
                            />
                        </Typography>
                    </Box>

                    {card?.clients && (
                        <Typography variant="h6" gutterBottom textAlign="left" sx={{ marginTop: '32px' }} color="white">
                            {t(`portfolio.clienti`)}
                        </Typography>
                    )}
                    {/* Logo clienti */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            columnGap: '20px',
                            color: 'white'
                        }}
                    >
                        {card?.clients &&
                            card.clients.map((client) => (
                                <img
                                    key={client}
                                    src={`/images/portfolio/clients-logos/${client}`}
                                    alt="client logo"
                                    style={{ width: '100px' }}
                                />
                            ))}
                    </Box>

                    <Typography variant="h6" gutterBottom textAlign="left" sx={{ marginTop: '32px' }} color="white">
                        {t(`portfolio.simili`)}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignContent: 'flex-start',
                            width: '100%',
                            marginTop: '1rem',
                            columnGap: '20px',
                            overflowX: 'auto',
                            overflowY: 'hidden',
                            scrollbarWidth: 'thin',
                            '&::-webkit-scrollbar': { height: '8px' },
                            '&::-webkit-scrollbar-thumb': { background: '#ccc', borderRadius: '4px' }
                        }}
                    >
                        {portfolioCards.map((c) => (
                            <PortfolioCard
                                key={c.id}
                                card={c}
                                title={c.title}
                                onClick={() => {
                                    onCardSelect(c.id);
                                    onClose();
                                }}
                            />
                        ))}
                    </Box>
                    <Typography variant="h4" textAlign="center" mt="32px" color="white">
                        {t(`portfolio.copy`)}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '32px',
                            marginBottom: '32px'
                        }}
                    >
                        <Button component={NavLink} to={getPath('/contattaci')} variant="portfolio">
                            {t(`portfolio.inner-action`)}
                        </Button>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default ProjectModal;
