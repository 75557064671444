import {Box, Typography, useMediaQuery} from "@mui/material";


const Footer = () => {
    const isMobile = useMediaQuery('(max-width:600px)');

    return(
        <Box sx={{
            width: '100vw',
            height:isMobile ? 'fit-content' : '30vh',
            backgroundColor: '#000'
        }}>
            <Box sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection:isMobile ? 'column' : 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                backgroundColor: '#000',
                padding:isMobile ? '10%' : '3%',
                columnGap:'5%'
            }}>

                {!isMobile && <img src={'/images/certificazione-iso.png'} style={{width: '100px'}} alt={'logo plesh'}/>}

                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItem: 'center',
                    justifyContent: 'center',
                    width: 'fit-content',
                }}>
                    <img src={'/images/logo-plesh.png'} style={{width: '150px'}} alt={'logo plesh'}/>
                    <Typography variant={'h6'} mt={2}>
                        IL TUO PARTNER TECNOLOGICO <br/> PER GLI EVENTI
                    </Typography>
                </Box>

                <Typography sx={{marginTop:isMobile ? '1rem' : 0}}>
                    Corso Alessandro Tassoni 79, 10143 TO <br/>
                    P.IVA IT12380050018 <br/>
                    Ragione Sociale Plesh Tech SRL <br/>
                    Capitale sociale 25.500€ <br/>
                </Typography>
                <Box sx={{marginTop:isMobile ? '1rem' : 0,display:'flex', flexDirection:'column', alignItem:'center', justifyContent:'center', width:'fit-content'}}>
                    <Typography>
                        Seguici sui social
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItem: 'center',
                        justifyContent: 'center',
                        width: 'fit-content'
                    }}>
                        <a href={'https://www.instagram.com/plesh.co?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=='}
                           target={'_blank'}>
                            <img src={'/images/instagram-brands-solid.svg'} style={{width: '35px', marginTop: '1rem'}}
                                 alt={'logo instagram'}/>
                        </a>
                        <a href={'https://www.linkedin.com/company/plesh/mycompany/'}
                           target={'_blank'}>
                            <img src={'/images/linkedin-brands-solid.svg'} style={{width: '35px', marginTop: '1rem', marginLeft:'1rem'}}
                                 alt={'logo instagram'}/>
                        </a>
                    </Box>


                </Box>
                {isMobile &&
                    <Box sx={{
                        display: 'flex',
                        alignItem: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        marginTop: isMobile ? '1rem' : 0
                    }}>
                        <img src={'/images/certificazione-iso.png'} style={{width: '100px'}} alt={'logo plesh'}/>
                    </Box>
                }

            </Box>
        </Box>
    )
}

export default Footer;