import dataCardStyle from "../stylesheets/DataCard.module.css";
import {Typography, useMediaQuery} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "@mui/material/styles";
import {Trans, useTranslation} from "react-i18next";

/* organicSpeedFactor determina la velocità con cui incrementa il valore in modo organico
*  < 1 -> Velocizza
*  > 1 -> Rallenta
* */
const DataCard = ({emoji, title, numero, organicSpeedFactor}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const {t} = useTranslation();

    const [displayNumber, setDisplayNumber] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const cardRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target); // Stop observing after it becomes visible
                }
            },
            { threshold: 0.5 } // Trigger when 10% of the element is visible
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => observer.disconnect();
    }, []);

    useEffect(() => {
        if (isVisible) {
            let currentNumber = 0;
            const targetNumber = numero;
            const incrementSpeed = 30;
            let organicTimeoutId;

            const fastIncrementInterval = setInterval(() => {
                currentNumber += Math.ceil(targetNumber / 100);
                if (currentNumber >= targetNumber) {
                    clearInterval(fastIncrementInterval);
                    setDisplayNumber(targetNumber);

                    // Start organic increment after reaching target
                    const startOrganicIncrement = () => {
                        setDisplayNumber(prev => prev + 1);

                        const minSpeed = 500; // Minimum speed
                        const maxSpeed = 5000; // Maximum speed
                        const baseSpeed =
                            Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed;

                        const organicSpeed = baseSpeed * organicSpeedFactor; // Multiply by the factor
                        organicTimeoutId = setTimeout(startOrganicIncrement, organicSpeed);
                    };

                    organicTimeoutId = setTimeout(startOrganicIncrement, 1000);

                    // Cleanup all timeouts upon component unmount
                    return () => clearTimeout(organicTimeoutId);
                } else {
                    setDisplayNumber(currentNumber);
                }
            }, incrementSpeed);

            return () => clearInterval(fastIncrementInterval);
        }
    }, [isVisible, numero, organicSpeedFactor]);

    return (
        <div ref={cardRef} className={dataCardStyle.mainContainer}>
            <div className={dataCardStyle.container}>

            </div>
            <div className={dataCardStyle.contentContainer}>
                <div className={dataCardStyle.titleContainer}>
                    <Typography variant={isMobile ? 'h5' : 'h4'}>{emoji}</Typography>
                    <Typography variant={isMobile ? 'h6' : 'h6'} fontWeight={'normal'}>
                        <Trans
                            i18nKey={`dataSection.${title}`}
                            components={{ br: <br /> }}
                        />
                    </Typography>
                </div>
                <Typography variant={isMobile ? 'h4' : 'h2'} fontWeight={'700'}
                    sx={{
                        background: 'linear-gradient(360deg, rgba(231,110,81,1) 0%, rgba(234,77,149,1) 100%)',
                        WebkitBackgroundClip: 'text',
                        WebkitTextFillColor: 'transparent',
                    }}
                >
                    {displayNumber.toLocaleString('it-IT')}
                </Typography>
            </div>
        </div>
    )
}

export default DataCard;