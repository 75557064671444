import {useMediaQuery} from "@mui/material";
import {LangSwitcher} from "../components/LangSwitcher";
import {useTranslation} from "react-i18next";
import {Header} from "../components/Header";
import HomeVideo from "../components/HomeVideo";
import BarraClienti from "../components/BarraClienti";
import CardSection from "../components/CardSection";
import DataSection from "../components/DataSection";
import FeatureSection from "../components/FeatureSection";
import ControlSection from "../components/ControlSection";
import SempliceSection from "../components/SempliceSection";
import AwardsSection from "../components/AwardsSection";
import {useEffect, useRef, useState} from "react";
import Footer from "../components/Footer";
import Loader from "../components/Loader";

const Home = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const waitForPageLoad = new Promise((resolve) => {
            if (document.readyState === 'complete') {
                resolve();
            } else {
                window.addEventListener('load', resolve, { once: true });
            }
        });

        const waitForFonts = document.fonts.ready;

        Promise.all([waitForPageLoad, waitForFonts]).then(() => {
            setIsLoading(false);
        });
    }, []);


    return(
        <>
            {
                isLoading ?
                    <Loader/>
                    :
                    <div>

                        <HomeVideo/>
                        <BarraClienti/>
                        <CardSection/>
                        <DataSection/>
                        <FeatureSection/>
                        <ControlSection/>
                        <SempliceSection/>
                        <AwardsSection/>
                        <Footer/>
                    </div>
            }
        </>


    )
}

export default Home
